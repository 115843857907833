<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData">
            Refresh
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      :server-items-length="totalItems"
      :options.sync="options"
      dense
      multi-sort
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 100],
      }"
      class="elevation-1"
    >
      <template v-slot:item.dailyWithdrawalLimit="{ item }">
        <span>{{ toMoneyString(item.dailyWithdrawalLimit) }}</span>
      </template>

      <template v-slot:item.oneWithdrawalLimit="{ item }">
        <span>{{ toMoneyString(item.oneWithdrawalLimit) }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-icon :title="item.status">
          {{ renderStatus(item.status) }}
        </v-icon>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toFormattedDateString(item.createdAt) }}
      </template>

      <template v-slot:item.updatedAt="{ item }">
        {{ toFormattedDateString(item.updatedAt) }}
      </template>

      <template v-if="readEnabled" v-slot:item.readButton="{ item }">
        <v-icon class="mr-2" @click="readItem(item)">mdi-file-document</v-icon>
      </template>

      <template v-slot:body.append>
        <v-form ref="form" v-model="valid">
          <v-dialog v-model="dialogDetail" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ $t("corporate-document-review") }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-divider></v-divider>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("id") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.id }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("user-id") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.userId }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("email") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.user ? detailItem.user.email : "" }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("withdrawal-limit.daily") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.dailyWithdrawalLimit }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("withdrawal-limit.one") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.oneWithdrawalLimit }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("status") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ renderStatus(detailItem.status) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("memo") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.comment }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("created-datetime") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.createdAt }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("updated-datetime") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ detailItem.updatedAt }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-container>
                  <v-data-table
                    :headers="historyHeaders"
                    :items="historyItems"
                    :loading="loading"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:item.actionType="{ item }">
                      <v-icon :title="item.actionType">
                        {{ renderActionType(item.actionType) }}
                      </v-icon>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                      {{ toFormattedDateString(item.createdAt) }}
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" @click="dialogDetail = false"
                  >{{ $t("close") }}
                </v-btn>
                <v-btn
                  color="success darken-1"
                  :disabled="!valid"
                  @click="
                    redirectCreateFormCorporateInformation(detailItem.userId)
                  "
                  >{{ $t("corporate-information") }} {{ $t("registration") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import store from "@/store";
import constants from "./constants";
import { getQueryString, filtersToSearchItems } from "@/utils/paginator";
import {
  renderCorporateAccountStatus,
  renderAdminUserActionType,
} from "@/utils/render-status";

const ViewID = constants.VIEW_CORPORATE_ACCOUNT;
const Actions = {
  read: "admin:CorporateAccount:Read",
};

export default {
  data: () => ({
    valid: false,
    dialogDetail: false,

    loading: true,
    options: {},
    itemsPerPage: 10,
    totalItems: 0,
    items: [],
    historyItems: [],

    searchItems: {
      userId: null,
    },
    filters: "",

    detailItem: {},
    countUserIds: 0,
    userIdString: "",

    snackbar: false,
    notificationMessage: "",

    permissions: [],
  }),
  computed: {
    headers() {
      const headersArr = [
        {
          text: this.$t("id"),
          value: "id",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("user-id"),
          value: "userId",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("email"),
          value: "user.email",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("withdrawal-limit.daily"),
          value: "dailyWithdrawalLimit",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("withdrawal-limit.one"),
          value: "oneWithdrawalLimit",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("status"),
          value: "status",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("created-datetime"),
          value: "createdAt",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("updated-datetime"),
          value: "updatedAt",
          filterable: false,
          sortable: false,
        },
      ];

      // coditional column by edit permission
      if (this.readEnabled) {
        headersArr.push({
          text: this.$t("more"),
          value: "readButton",
          filterable: false,
          sortable: false,
        });
      }
      return headersArr;
    },

    historyHeaders() {
      const headersArr = [
        {
          text: this.$t("admin-user-name"),
          value: "adminUser.name",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("action-type"),
          value: "actionType",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("created-datetime"),
          value: "createdAt",
          filterable: false,
          sortable: false,
        },
      ];
      return headersArr;
    },

    readEnabled() {
      return this.permissions.indexOf(Actions.read) >= 0;
    },

    renderStatus() {
      return (status) => renderCorporateAccountStatus(status);
    },

    renderActionType() {
      return (actionType) => renderAdminUserActionType(actionType);
    },
  },

  watch: {
    options: {
      async handler() {
        // TO BE FILLED filtering code when user_id searching
        await this.loadData();
      },
      deep: true,
    },
  },
  mounted() {
    Promise.all([this.getPermissions()]);
  },
  methods: {
    clearFilters() {
      this.filters = null;
    },

    /**
     * 현재 화면에 대한 권한을 로드한다.
     */
    async getPermissions() {
      try {
        // 백엔드에서 요청한 현재 화면에서 허용된 액션 목록
        const res = await this.authGet(`/api/auth/perm/views/${ViewID}`);
        this.permissions = res.data.data || [];

        // Store에 저장된 현재 화면에서 허용된 액션 목록
        const allowedActions = store.getters.getViewAllowedActions(ViewID);

        console.log("permissions: ", this.permissions);
        console.log("allowedActions: ", allowedActions);

        // TODO: 페이지가 마운트될 때마다 권한을 읽어올지, Store에 저장된 권한을 읽어올 지 선택 필요
      } catch (e) {
        this.processError(e);
      }
    },

    async refreshData() {
      await this.loadData(true);
      const refreshedPath = "/corporate/corporate-account";
      const currentPath = this.$route.path;
      if (refreshedPath !== currentPath) {
        await this.$router.push(refreshedPath);
      }
    },

    async loadData(isRefresh = false) {
      try {
        if (isRefresh) {
          this.clearFilters();
        }
        this.loading = true;
        // console.log("options:", this.options);
        const { page, itemsPerPage: limits } = this.options;
        const queryString = getQueryString({
          limits,
          page,
          filters: this.filters,
        });

        const requestUrl = `/api/corporate-account${queryString}`;
        const res = await this.authGet(requestUrl);
        this.items = res.data.data;
        this.totalItems = Number(res.data.totalItems);
        this.filters = res.data.filters;
        this.searchItems = filtersToSearchItems(this.filters);
        console.log("in refreshData,", {
          msal: this.$msal,
          page,
          limits,
          filters: this.filters,
          queryString,
          requestUrl,
          totalItems: this.totalItems,
          searchItems: this.searchItems,
        });
        this.loading = false;
      } catch (e) {
        this.processError(e);
      }
    },

    async readItem(item) {
      try {
        this.detailItem = item;

        const requestUrl = `/api/corporate-account/admin-history/${item.userId}`;
        const res = await this.authGet(requestUrl);
        console.log(res.data.data);
        this.historyItems = res.data.data;
        this.loading = false;
        this.dialogDetail = true;
      } catch (e) {
        this.processError(e);
      }
    },

    redirectCreateFormCorporateInformation(userId) {
      console.log("REDIRECT CREATE FORM CORPORATE INFORMATION");
      this.dialogDetail = false;
      this.$router.push({ path: `/corporate/corporate-info/${userId}/create` });
    },

    processError(e) {
      const { response } = e;
      if (response) {
        this.showNotification(response.data);
      } else {
        console.error(e);
        this.showNotification(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>
